<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <v-menu
    v-model="isOpen.menu"
    offset-y
    left
    nudge-bottom="14"
    min-width="300"
    :close-on-content-click="false"
    content-class="cash-register-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-icon
            color="primary"
            size="28"
          >
            {{ icons.mdiCashRegister }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>

    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-icon
              color="primary"
              size="28"
            >
              {{ icons.mdiCashRegister }}
            </v-icon>
          </v-avatar>
        </v-badge>

        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <small class="text--disabled text-xs">時点レジ金</small>
          <span
            class="font-weight-semibold mb-n1"
            :class="currentClubMeta.remainingCashRegisterMoney < 0 ? 'error--text' : 'text--primary '"
          >
            ¥{{ currentClubMeta.remainingCashRegisterMoney.toLocaleString() }}
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- 入金 -->
      <v-list-group
        v-model="isOpen.deposit"
      >
        <template #activator>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCashPlus }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>入金</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model.number="cashRegisterTxForDeposit.amount"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              :error="!isValidDeposit"
              min="0"
              prefix="¥"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="cashRegisterTxForDeposit.note"
              placeholder="摘要"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-btn
              small
              :disabled="!isValidDeposit"
              color="primary"
              :ripple="false"
              @click="createCashRegisterTransaction(cashRegisterTxForDeposit)"
            >
              入金
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- 出金 -->
      <v-list-group
        v-model="isOpen.withdraw"
      >
        <template #activator>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCashMinus }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>出金</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model.number="cashRegisterTxForWithdraw.amount"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              :error="!isValidWithdraw"
              min="0"
              :max="currentClubMeta.remainingCashRegisterMoney"
              prefix="¥"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="cashRegisterTxForWithdraw.note"
              placeholder="摘要"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-btn
              small
              :disabled="!isValidWithdraw"
              color="primary"
              :ripple="false"
              @click="createCashRegisterTransaction(cashRegisterTxForWithdraw)"
            >
              出金
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-row>
        <v-col class="ms-4 mt-1">
          <router-link
            :to="{
              name: 'waiter-cash-register-transactions',
              query: {
                ...{ date: $route.query.date ? $route.query.date : currentClubMeta.today || new Date().toISOString().substr(0, 10) },
                ...{ interval: $route.query.interval ? $route.query.interval : 0 },
              },
            }"
            @click.native="isOpen.menu = false"
          >
            <span class="text-decoration-underline cursor-pointer link--text text-sm">
              Tx詳細
            </span>
          </router-link>
        </v-col>
      </v-row>

      <v-divider class="my-2"></v-divider>

      <!-- 売上 -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>売上合計</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-subheader class="text-xs ms-1">
        未清算
      </v-subheader>
      <v-list-item>
        <v-list-item-icon class="me-2 ms-4">
          <v-icon size="22">
            {{ icons.mdiSprout }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>¥{{ currentClubMeta.todaySalesUnfixed.toLocaleString() }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-subheader class="text-xs ms-1">
        清算済
      </v-subheader>
      <v-list-item>
        <v-list-item-icon class="me-2 ms-4">
          <v-icon size="22">
            {{ icons.mdiFlowerTulip }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>¥{{ currentClubMeta.todaySales.toLocaleString() }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- 領収書 -->
      <v-list-group
        v-model="isOpen.japAssReceiptPrint"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>領収書</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="receipt-slip-setting">
          <v-list-item-content>
            <receipt-slip-setting
              :customer-name="receiptSlipAdvancedOptions.customerName"
              :receipt-note="receiptSlipAdvancedOptions.receiptNote"
              :date="receiptSlipAdvancedOptions.date"
              :receipt-note-presets="receiptNotePresets"
              @update:customerName="receiptSlipAdvancedOptions.customerName = $event"
              @update:receiptNote="receiptSlipAdvancedOptions.receiptNote = $event"
              @update:date="receiptSlipAdvancedOptions.date = $event"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model.number="japAssReceiptPrintAmount"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              min="0"
              prefix="¥"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <print-btn
              subject="領収書"
              :print="japAssReceiptPrint"
              :block="true"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- 債務確認書 -->
      <v-list-group
        v-model="isOpen.debtConfirmationPrint"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>債務確認書</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model.number="debtConfirmationAmount"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              min="0"
              prefix="¥"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <print-btn
              subject="債務確認書"
              :print="debtConfirmationPrint"
              :block="true"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-divider class="my-2"></v-divider>

      <v-list-item>
        <v-list-item-content>
          <span class="d-flex align-center">
            プリンター:
            <span
              v-if="isReceiptPrinterConnecting"
              class="secondary--text ml-1 d-flex align-center"
            >
              接続中
              <clip-loader
                :loading="true"
                :color="'#8A8D93'"
                size="16px"
                style="text-align: left !important;"
              />
            </span>
            <template v-else>
              <span
                v-if="printer && printer.ePosDev && printer.ePosDev.isConnected"
                class="success--text ml-1"
              >
                接続済
              </span>
              <span
                v-else
                class="secondary--text ml-1"
              >
                未接続
              </span>
            </template>
          </span>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        class="secondary--text"
        @click="logoutUser"
      >
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            color="secondary"
          >
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="secondary--text">
          <v-list-item-title>
            ログアウト
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  computed,
  watch,
  inject,
} from '@vue/composition-api'
import {
  mdiCashRegister,
  mdiCashPlus,
  mdiCashMinus,
  mdiSprout,
  mdiFlowerTulip,
  mdiLogoutVariant,
  mdiUpload,
  mdiDownload,
} from '@mdi/js'
import {
  cloneDeep,
} from 'lodash'
import { useRouter } from '@core/utils'
import { useReceiptSlipPrint, useDebtConfirmationSlipPrint } from '@yonarezi/yonarezi3.slip-print'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'
import CashRegisterTransactionApi from '@/api/waiter/CashRegisterTransaction'
import { initialAbility } from '@/plugins/acl/config'
import { signOut, clearAuthLocalStorage } from '@/api/auth'
import PrintBtn from '@/views/components/util/PrintBtn.vue'
import ReceiptSlipSetting from '@/views/components/table/TableShowDrawer/Check/ReceiptSlipSetting.vue'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  components: {
    PrintBtn,
    ClipLoader,
    ReceiptSlipSetting,
  },
  setup() {
    const currentClubMeta = inject('currentClubMeta', {})
    const printer = inject('printer', null)
    const isReceiptPrinterConnecting = inject('isReceiptPrinterConnecting', null)
    const receiptNotePresets = inject('receiptNotePresets', [])
    const vm = getCurrentInstance().proxy
    const { router, route } = useRouter()
    const { currentClub } = useCurrentData()
    const japAssReceiptPrintAmount = ref(0)
    const debtConfirmationAmount = ref(0)
    const receiptSlipAdvancedOptions = reactive({
      customerName: '',
      receiptNote: receiptNotePresets.value.length > 0 ? receiptNotePresets.value[0].attributes.content : '',
      date: new Date(),
      receiptSlipNumber: '',
    })

    const isOpen = reactive({
      menu: false,
      deposit: false,
      withdraw: false,
      japAssReceiptPrint: false,
      debtConfirmationPrint: false,
    })

    const cashRegisterTxForDeposit = reactive({
      amount: 0,
      note: '',
      transactionType: 'deposit',
    })

    const cashRegisterTxForWithdraw = reactive({
      amount: 0,
      note: '',
      transactionType: 'withdraw',
    })

    const isValidDeposit = computed(() => {
      return cashRegisterTxForDeposit.transactionType === 'deposit'
        && cashRegisterTxForDeposit.amount > 0
    })

    // NOTE: レジmenuからの出金指示は時点レジ金以上を指示できないようにする(client側だけの制御)
    // 会計時に時点レジ金以上の出金指示になってしまうのは許容する（マイナスがわかればいい、入金指示してから会計するのはフロー的に実務的じゃない）
    const isValidWithdraw = computed(() => {
      return cashRegisterTxForWithdraw.transactionType === 'withdraw'
        && cashRegisterTxForWithdraw.amount > 0
        && cashRegisterTxForWithdraw.amount <= currentClubMeta.remainingCashRegisterMoney
    })

    const cashRegisterTx = computed(() => {
      return {
        deposit: cashRegisterTxForDeposit,
        withdraw: cashRegisterTxForWithdraw,
      }
    })

    const isValid = computed(() => {
      return {
        deposit: isValidDeposit,
        withdraw: isValidWithdraw,
      }
    })

    const logoutUser = async () => {
      await signOut()
        .then(() => {
          clearAuthLocalStorage()

          // Reset ability
          vm.$ability.update(initialAbility)

          // Redirect to login page
          router.push({ name: 'auth-login' })
        })
        .catch(err => {
          console.error(err)

          clearAuthLocalStorage()

          if (route.value.name !== 'auth-login') router.push({ name: 'auth-login' })
        })
    }

    const createCashRegisterTransaction = async txObj => {
      if (isValid.value[txObj.transactionType]) {
        const res = await CashRegisterTransactionApi.createCashRegisterTransaction(txObj)

        if (res) {
          currentClubMeta.remainingCashRegisterMoney = res?.data

          const transactionTypeLocale = {
            deposit: '入金',
            withdraw: '出金',
          }
          vm.$toast.success(`¥${txObj.amount.toLocaleString()} ${transactionTypeLocale[txObj.transactionType]}しました`)
        }

        cashRegisterTx.value[txObj.transactionType].amount = 0
        cashRegisterTx.value[txObj.transactionType].note = ''
        isOpen[txObj.transactionType] = false

        // NOTE: レジ詳細画面で実行したらリロードして一覧に表示させる
        if (route.value.name === 'waiter-cash-register-transactions') router.go(0)
      }
    }

    // NOTE: YONAREZI 3
    const slipPrintBaseOptions = computed(() => {
      return {
        ipAddress: removePortFromIpAddress(currentClub.value.printIpAddress),
        clubName: currentClub.value.name,
        invoiceNumber: currentClub.value.invoiceNumber,
        address: currentClub.value.address,
        tel: currentClub.value.telephoneNumber,
        printer: printer.value,
      }
    })
    const receiptSlipOptions = computed(() => {
      return {
        ...slipPrintBaseOptions.value,
        ...receiptSlipAdvancedOptions,
        total: japAssReceiptPrintAmount.value,
        ...receiptSlipAdvancedOptions,
      }
    })
    const debtConfirmationSlipOptions = computed(() => {
      return {
        ...slipPrintBaseOptions.value,
        balanceReceivableAmount: debtConfirmationAmount.value,
      }
    })
    const { send: printReceiptSlip, onReceive: onReceiveReceiptSlipPrint } = useReceiptSlipPrint(receiptSlipOptions)
    const { send: printDebtConfirmationSlip, onReceive: onReceiveDebtConfirmationSlipPrint } = useDebtConfirmationSlipPrint(debtConfirmationSlipOptions)
    onReceiveReceiptSlipPrint.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }
    onReceiveDebtConfirmationSlipPrint.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }
    const japAssReceiptPrint = async () => {
      await printReceiptSlip()
      console.log('receiptSlipOptions', receiptSlipOptions.value)
      japAssReceiptPrintAmount.value = 0
      isOpen.japAssReceiptPrint = false
    }
    const debtConfirmationPrint = async () => {
      await printDebtConfirmationSlip()
      debtConfirmationAmount.value = 0
      isOpen.debtConfirmationPrint = false
    }

    watch(() => cloneDeep(isOpen), (newVal, _prevVal) => {
      if (!newVal.deposit) cashRegisterTxForDeposit.amount = 0

      if (!newVal.withdraw) cashRegisterTxForWithdraw.amount = 0

      if (!newVal.menu) {
        isOpen.deposit = false
        isOpen.withdraw = false
      }
    })

    return {
      currentClubMeta,
      isOpen,
      cashRegisterTxForDeposit,
      cashRegisterTxForWithdraw,
      isValidDeposit,
      isValidWithdraw,
      isReceiptPrinterConnecting,
      printer,
      createCashRegisterTransaction,
      logoutUser,
      japAssReceiptPrint,
      debtConfirmationPrint,
      japAssReceiptPrintAmount,
      debtConfirmationAmount,
      receiptSlipAdvancedOptions,
      receiptNotePresets,
      icons: {
        mdiCashRegister,
        mdiCashPlus,
        mdiCashMinus,
        mdiSprout,
        mdiFlowerTulip,
        mdiLogoutVariant,
        mdiUpload,
        mdiDownload,
      },
    }
  },
}
</script>

<style lang="scss">
.cash-register-menu-content {
  max-height: 80vh;
  overflow-y: auto;

  .v-list-item {
    min-height: 2.5rem !important;
    /* margin: 4px 0; */
  }
  .v-subheader {
    height: 24px;
  }
  .receipt-slip-setting {
    height: 100% !important;
  }
}
</style>
