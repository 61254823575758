<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div>
    <v-menu
      v-model="menu"
      offset-y
      left
      nudge-bottom="14"
      max-width="360"
      min-width="360"
      :close-on-content-click="false"
      content-class="monitor-menu-content"
    >
      <template #activator="{ on, attrs }">
        <v-badge
          :color="diffCountBadgeColor"
          :content="diffCountText"
          right
          offset-x="12"
          offset-y="28"
          overlap
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              size="28"
            >
              {{ icons.mdiHomeAccount }}
            </v-icon>
          </v-avatar>
        </v-badge>
      </template>

      <v-list>
        <v-subheader class="d-flex align-center">
          プラマイ
          <v-btn
            icon
            :ripple="false"
            x-small
            @click="reloadPlusMinusStats"
          >
            <v-icon>
              {{ icons.mdiReload }}
            </v-icon>
          </v-btn>
        </v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                出勤中キャスト
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  v-if="isLoading.castWorkingTime"
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
                <span v-else>
                  {{ currentStats.castCount }}人
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                現在の組数 / 客数
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  v-if="isLoading.castWorkingTime"
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
                <span v-else>
                  {{ currentStats.tableCount }}組 / {{ currentStats.customerCount }}人
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                ＋ー
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  v-if="isLoading.castWorkingTime"
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
                <span v-else>
                  {{ diffCountText }}
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-subheader class="mt-4 d-flex align-center">
          稼働率
          <v-btn
            icon
            :ripple="false"
            x-small
            @click="reloadOperationRateStats"
          >
            <v-icon>
              {{ icons.mdiReload }}
            </v-icon>
          </v-btn>
        </v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                キャスト稼働時間
              </v-col>
              <v-col
                v-if="isLoading.customerUsageTime"
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
              </v-col>
              <template v-else>
                <v-col
                  cols="2"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="editableCastWorkingTimes.hours"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                  />
                  :
                </v-col>
                <v-col
                  cols="2"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="editableCastWorkingTimes.minutes"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                  />
                </v-col>
              </template>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                セット時間
              </v-col>
              <v-col
                v-if="isLoading.customerUsageTime"
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
              </v-col>
              <template v-else>
                <v-col
                  cols="2"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="editableCustomerUsageTimes.hours"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                  />
                  :
                </v-col>
                <v-col
                  cols="2"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="editableCustomerUsageTimes.minutes"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                  />
                </v-col>
              </template>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item>
          <v-list-item-content>
            <v-row dense>
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                稼働率
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <clip-loader
                  v-if="isLoading.customerUsageTime"
                  :loading="true"
                  :color="'#8A8D93'"
                  size="16px"
                  class="d-flex align-items pr-2"
                />
                <span v-else>
                  {{ operationRate }}%
                </span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { mdiHomeAccount, mdiReload } from '@mdi/js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ClipLoader,
  },
  setup() {
    const currentStats = inject('currentStats', {
      castCount: 0,
      tableCount: 0,
      customerCount: 0,
      castWorkingTime: 0,
      customerUsageTime: 0,
    })
    const fetchPlusMinusStats = inject('fetchPlusMinusStats', () => {})
    const fetchOperationRateStats = inject('fetchOperationRateStats', () => {})

    const menu = ref(false)
    const lastMenuOpenAt = ref(Date.now())
    const editableCastWorkingTimes = reactive({ hours: 0, minutes: 0 })
    const editableCustomerUsageTimes = reactive({ hours: 0, minutes: 0 })
    const isLoading = reactive({ castWorkingTime: false, customerUsageTime: false })

    const diffCount = computed(() => currentStats.castCount - currentStats.customerCount)
    const diffCountText = computed(() => {
      if (diffCount.value > 0) return `+${diffCount.value}`
      if (diffCount.value < 0) return diffCount.value

      return '±0'
    })
    const diffCountBadgeColor = computed(() => (diffCount.value > 0 ? 'info' : 'error'))
    const castWorkingTime = computed(() => Number(editableCastWorkingTimes.hours) * 3600 + Number(editableCastWorkingTimes.minutes) * 60)
    const customerUsageTime = computed(() => Number(editableCustomerUsageTimes.hours) * 3600 + Number(editableCustomerUsageTimes.minutes) * 60)
    const operationRate = computed(() => {
      if (customerUsageTime.value > 0 && castWorkingTime.value > 0) {
        const value = customerUsageTime.value / castWorkingTime.value

        return Math.floor(value * 10000) / 100
      }

      return 0
    })

    function secondsToHours(seconds) { return Math.floor(seconds / 3600) }
    function secondsToMinutes(seconds) { return Math.floor((seconds % 3600) / 60) }
    function resetEditableCastWorkingTimes() {
      editableCastWorkingTimes.hours = secondsToHours(currentStats.castWorkingTime)
      editableCastWorkingTimes.minutes = secondsToMinutes(currentStats.castWorkingTime)
    }
    function resetEditableCustomerUsageTimes() {
      editableCustomerUsageTimes.hours = secondsToHours(currentStats.customerUsageTime)
      editableCustomerUsageTimes.minutes = secondsToMinutes(currentStats.customerUsageTime)
    }
    async function reloadPlusMinusStats() {
      isLoading.castWorkingTime = true
      await fetchPlusMinusStats()
      resetEditableCastWorkingTimes()
      isLoading.castWorkingTime = false
    }
    async function reloadOperationRateStats() {
      isLoading.customerUsageTime = true
      await fetchOperationRateStats()
      resetEditableCustomerUsageTimes()
      isLoading.customerUsageTime = false
    }

    watch(() => currentStats.castWorkingTime, val => {
      editableCastWorkingTimes.hours = secondsToHours(val)
      editableCastWorkingTimes.minutes = secondsToMinutes(val)
    })
    watch(() => currentStats.customerUsageTime, val => {
      editableCustomerUsageTimes.hours = secondsToHours(val)
      editableCustomerUsageTimes.minutes = secondsToMinutes(val)
    })
    watch(() => menu.value, val => {
      if (val) {
        const currentTime = Date.now()
        const oneMinute = 60000 // ミリ秒で1分

        if (currentTime - lastMenuOpenAt.value >= oneMinute) {
          reloadPlusMinusStats()
          reloadOperationRateStats()
          lastMenuOpenAt.value = currentTime
        }
      }
    })

    return {
      currentStats,
      menu,
      isLoading,

      diffCount,
      diffCountText,
      diffCountBadgeColor,
      operationRate,
      castWorkingTime,
      customerUsageTime,
      editableCastWorkingTimes,
      editableCustomerUsageTimes,

      reloadPlusMinusStats,
      reloadOperationRateStats,

      icons: {
        mdiHomeAccount,
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss">
.monitor-menu-content {
  .min-content-width {
    min-width: min-content !important;
  }
}
</style>
